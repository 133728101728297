<!-- 关于我们左侧 -->
<template>
  <div class="guide-lt" >
    <div class="item" :class='{choice:choice==0}' @click='choiceItem(0)'>
      <span class="sp">购物指南</span>
      <svg class='down' v-if='choice!=0'><use xlink:href="#iconziyuan"></use></svg>
      <svg class='up' v-if='choice==0'><use xlink:href="#iconziyuan7"></use></svg>
      <div class="menu"  :style='{height:choice==0?"auto":"0px"}'>
        <button   @click='register'>新用户注册</button>
        <button   @click='findpas'>找回密码</button>
        <button  @click='links(0)' :class='{choicem:type==0}'>购物流程</button>
      </div>
    </div>
    <div class="item" :class='{choice:choice==1}' @click='choiceItem(1)'>
      <span class="sp">安全支付</span>
      <svg class='down' v-if='choice!=1'><use xlink:href="#iconziyuan"></use></svg>
      <svg class='up' v-if='choice==1'><use xlink:href="#iconziyuan7"></use></svg>
      <div class="menu" :style='{height:choice==1?"auto":"0px"}'>
        <button @click='links(1)' :class='{choicem:type==1}'>在线支付</button>
        <button @click='links(2)' :class='{choicem:type==2}'>银行汇款</button>
        <button @click='links(3)' :class='{choicem:type==3}'>发票说明</button>
      </div>
    </div>
    <div class="item" :class='{choice:choice==2}' @click='choiceItem(2)'>
      <span class="sp">关于配送</span>
      <svg class='down' v-if='choice!=2'><use xlink:href="#iconziyuan"></use></svg>
      <svg class='up' v-if='choice==2'><use xlink:href="#iconziyuan7"></use></svg>
      <div class="menu" :style='{height:choice==2?"auto":"0px"}'>
        <button @click='links(4)' :class='{choicem:type==4}'>配送方式</button>
        <button @click='links(5)' :class='{choicem:type==5}'>配送范围</button>
        <button @click='links(6)' :class='{choicem:type==6}'>签收与验货</button>
      </div>
    </div>
    <div class="item" :class='{choice:choice==3}' @click='choiceItem(3)'>
      <span class="sp">关于定制</span>
      <svg class='down' v-if='choice!=3'><use xlink:href="#iconziyuan"></use></svg>
      <svg class='up' v-if='choice==3'><use xlink:href="#iconziyuan7"></use></svg>
      <div class="menu" :style='{height:choice==3?"auto":"0px"}'>
        <button @click='links(7)' :class='{choicem:type==7}'>定制流程</button>
        <button @click='links(8)' :class='{choicem:type==8}'>在线设计</button>
        <button @click='links(9)' :class='{choicem:type==9}'>定制需知</button>
        <button @click='links(10)' :class='{choicem:type==10}'>注意事项</button>
      </div>
    </div>
    <div class="item" :class='{choice:choice==4}' @click='choiceItem(4)'>
      <span class="sp">售后服务</span>
      <svg class='down' v-if='choice!=4'><use xlink:href="#iconziyuan"></use></svg>
      <svg class='up' v-if='choice==4'><use xlink:href="#iconziyuan7"></use></svg>
      <div class="menu" :style='{height:choice==4?"auto":"0px"}'>
        <button @click='links(11)' :class='{choicem:type==11}'>售后政策</button>
        <button @click='links(12)' :class='{choicem:type==12}'>退款说明</button>
        <button @click='links(13)' :class='{choicem:type==13}'>返工/退换货</button>
        <button @click='links(14)' :class='{choicem:type==14}'>取消订单</button>
        <button @click='links(15)' :class='{choicem:type==15}'>人工服务</button>
      </div>
    </div>
    <div class="item" :class='{choice:choice==5}' @click='choiceItem(5)'>
      <span class="sp">帮助中心</span>
      <svg class='down' v-if='choice!=5'><use xlink:href="#iconziyuan"></use></svg>
      <svg class='up' v-if='choice==5'><use xlink:href="#iconziyuan7"></use></svg>
      <div class="menu" :style='{height:choice==5?"auto":"0px"}'>
        <button @click='links(16)' :class='{choicem:type==16}'>常见问题</button>
        <!-- <button @click='links(17)' :class='{choicem:type==17}'>给我们提建议</button> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-lt',
  props: {
    type: Number
  },
  data(){
    return {
      choice:null
    }
  },
  methods:{
    register(){
      // 注册
      this._router({then:()=>{
        this.$router.push({path: "/accountregister.html"})
      }})
    },
    findpas(){
      // 找回密码
      this._router({then:()=>{
        this.$router.push({path: "/setsteps?types=3.html"})
      }})
    },
    choiceItem(v){
      // 选择分类
      if(this.choice!=v){
        this.choice = v
      }else {
        this.choice = null
      }
    },
    links(v){
      switch(v) {
        case 0:
          this._router({then:()=>{
            this.$router.push({path: "/guideshop.html"})
          }})
          break;
        case 1:
          this._router({then:()=>{
            this.$router.push({path: "/guideonlinepay.html"})
          }})
          break;
        case 2:
          this._router({then:()=>{
            this.$router.push({path: "/guidebank.html"})
          }})
          break;
        case 3:
          this._router({then:()=>{
            this.$router.push({path: "/guidenote.html"})
          }})
          break;
        case 4:
          this._router({then:()=>{
            this.$router.push({path: "/guidesend.html"})
          }})
          break;
        case 5:
          this._router({then:()=>{
            this.$router.push({path: "/guiderange.html"})
          }})
          break;
        case 6:
          this._router({then:()=>{
            this.$router.push({path: "/guidetake.html"})
          }})
          break;
        case 7:
          this._router({then:()=>{
            this.$router.push({path: "/guidemade.html"})
          }})
          break;
        case 8:
          this._router({then:()=>{
            this.$router.push({path: "/guidedesign.html"})
          }})
          break;
        case 9:
          this._router({then:()=>{
            this.$router.push({path: "/guidemadeknow.html"})
          }})
          break;
        case 10:
          this._router({then:()=>{
            this.$router.push({path: "/guidekeepitem.html"})
          }})
          break;
        case 11:
          this._router({then:()=>{
            this.$router.push({path: "/guidesale.html"})
          }})
          break;
        case 12:
          this._router({then:()=>{
            this.$router.push({path: "/guiderefund.html"})
          }})
          break;
        case 13:
          this._router({then:()=>{
            this.$router.push({path: "/guiderework.html"})
          }})
          break;
        case 14:
          this._router({then:()=>{
            this.$router.push({path: "/guidecancel.html"})
          }})
          break;
        case 15:
          this._router({then:()=>{
            this.$router.push({path: "/guidelabour.html"})
          }})
          break;
        case 16:
          this._router({then:()=>{
            this.$router.push({path: "/guidequestion.html"})
          }})
          break;
        case 17:
          this._router({then:()=>{
            this.$router.push({path: "/guidepropose.html"})
          }})
          break;
        default:
          break;
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.type ==0){
        this.choice = 0
      }
      if(this.type>0&&this.type<4){
        this.choice = 1
      }
      if(this.type>3&&this.type<7){
        this.choice = 2
      }
      if(this.type>6&&this.type<11){
        this.choice = 3
      }
      if(this.type>10&&this.type<16){
        this.choice = 4
      }
      if(this.type>15&&this.type<18){
        this.choice = 5
      }
    })
  },
}
</script>
<style  lang="scss" scoped>
.guide-lt {
  width: 234px;background: #fff;border-radius: 5px;float:left;padding-bottom:10px;
  .item {
    width: 204px;background: none;text-align: left;padding-left:30px;position: relative;color:#262626;font-weight: bold;cursor: pointer;overflow: hidden;
    .sp {
      height: 60px;line-height: 60px;display: block;
    }
    .menu {
      height: 0px;transition: height 0.5s;
      button {
        display: block;background: none;font-size: 12px;color:#555;height: 26px;line-height: 26px;
      }
      .choicem {
        color:#f88288;
      }
    }
    .up,.down {
      position: absolute;top:22px;right:20px;width: 15px;height: 15px;fill: #333;
    }
    &:after {
      content: '';display: none;width: 6px;height: 48px;background: #fe8385;border-radius: 5px;position: absolute;top:6px;left:0px;
    }
  }
  .choice {
    &:after {
      display: block;
    }
  }
}
</style>
