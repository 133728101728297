<template>
  <div class="guide-cnt2">
    <h6 class="h6">银行汇款</h6>
    <div class="cnt">
      <p class="p">1、关于银行转账：</p>
      <p class="p">1)国内顾客可以通过全国任何一家银行，向微微定指定对公账户汇款；</p>
      <p class="p">2)到款时间一般为办理转帐手续之后的1-3个工作日内；</p>
      <p class="p ps">3)订单提交成功后，请您及时汇款，汇款完毕后请务必联系在线客服，进行登记并确认付款信息，如您24小时内未联系客服确认，
订单将会被系统自动取消。</p>
      <p class="p ">2、银行汇款账户：</p>
      <p class="p">公司名称：深圳市微微数码有限公司</p>
      <p class="p">开户行：民生银行深圳沙井支行</p>
      <p class="p">开户账号：691308275</p>
      <p class="p">地址：深圳市宝安区松岗街道燕川燕山大道3号B栋</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt2',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt2 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-top:10px;
    }
    .ps {
      margin-bottom:30px;
    }
  }
}
</style>
